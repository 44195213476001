import React from 'react';
import { List, ListItem } from '@mui/material';
import RequiredDocumentItem from '../RequiredDocumentItem/RequiredDocumentItem';
import { Document } from '../DocumentItem/DocumentItem';

export type RequiredDocument = {
    id: number;
    fichierId?: number;
    uploadable: boolean;
    uploading: boolean;
    editable: boolean;
    showable: boolean;
    removable: boolean;
    downloadable: boolean;
} & Document;

export type RequiredDocumentsListProps = {
    documents: Array<RequiredDocument>;
    onUpload: (documentId: number, file: File, fichierId?: number) => void;
    onDeletion: (fichierId: number) => void;
    onDownload: (fichierId: number) => void;
    onShow: (label: string, fichierId: number) => void;
};

const RequiredDocumentsList: React.FunctionComponent<RequiredDocumentsListProps> = function (
    props,
) {
    const {
        documents,
        onUpload: handleUpload,
        onDeletion: handleDeletion,
        onDownload: handleDownload,
        onShow: handleShow,
    } = props;

    const getItemKey = (document: RequiredDocument) => {
        if (document.fichierId) {
            return `${document.id}-${document.fichierId}`;
        }

        return String(document.id);
    };

    const listSx = {
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px 0',
    };

    const listItemSx = {
        padding: '0',
        display: 'block',
    };

    const items = documents.map((document) => (
        <ListItem
            key={getItemKey(document)}
            sx={listItemSx}
            data-testid="required-document-list-item"
        >
            <RequiredDocumentItem
                {...document}
                onChange={
                    document.fichierId
                        ? (files) =>
                              handleUpload(
                                  document.id as number,
                                  files[0],
                                  document.fichierId as number,
                              )
                        : (files) => handleUpload(document.id as number, files[0])
                }
                onDeletion={
                    document.fichierId
                        ? () => handleDeletion(document.fichierId as number)
                        : () => {}
                }
                onDownload={
                    document.fichierId
                        ? () => handleDownload(document.fichierId as number)
                        : () => {}
                }
                onShow={
                    document.fichierId
                        ? () => handleShow(document.label, document.fichierId as number)
                        : () => {}
                }
            />
        </ListItem>
    ));

    return <List sx={listSx}>{items}</List>;
};

export default RequiredDocumentsList;
