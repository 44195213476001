import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { Status } from '@europrocurement/l2d-domain';
import { useParams } from 'react-router-dom';
import DocumentContext from './DocumentContext';
import { DossierDocument, DossierFichier, useDossierDocuments } from '../hooks';

const DocumentProvider: React.FunctionComponent<PropsWithChildren> = function ({ children }) {
    const { idDossier } = useParams();
    const [statuses, setStatuses] = useState<Array<Status>>([]);
    const [documents, setDocuments] = useState<Array<DossierDocument>>([]);
    const [fichiers, setFichiers] = useState<Array<DossierFichier>>([]);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState<number>(0);
    const {
        getDocumentStatuses,
        getDossierDocuments,
        getDossierFichiers,
        getFichierFromDocument,
        isNoType,
        isClientDocumentType,
        isFormalisteDocumentType,
        documentHasFichier,
        getStatusIdentifier,
    } = useDossierDocuments();

    const getStatusLabel = useCallback(
        (statusId: number): string | undefined | null => {
            const status = statuses.find((s) => Number(s.id) === Number(statusId));

            return status ? status.libelle : null;
        },
        [statuses],
    );

    const addFichier = useCallback(
        (fichier: DossierFichier) => {
            setFichiers([...fichiers, fichier]);
        },
        [fichiers, setFichiers],
    );

    const addMultipleFichiers = useCallback(
        (newFichiers: DossierFichier[]) => {
            setFichiers([...fichiers, ...newFichiers]);
        },
        [fichiers, setFichiers],
    );

    const removeFichier = useCallback(
        (fichier: DossierFichier) => {
            const newState = [...fichiers];
            setFichiers(newState.filter((f) => f.id !== fichier.id));
        },
        [fichiers, setFichiers],
    );

    const replaceFichier = useCallback(
        (oldFichier: DossierFichier, newFichier: DossierFichier) => {
            const newState = fichiers.filter((f) => f.id !== oldFichier.id);
            newState.push(newFichier);
            setFichiers(newState);
        },
        [fichiers, setFichiers],
    );

    const isRequiredDocumentInError = (fichier: DossierFichier): boolean => {
        const statusId = fichier?.statut?.id;

        return undefined !== statusId && getStatusIdentifier(statusId) === 'error';
    };

    useEffect(
        (): void => {
            const nb = documents.filter((document) => {
                if (
                    isNoType(document?.document?.id as number) ||
                    !isClientDocumentType(document?.document?.type as number)
                ) {
                    return false;
                }
                const fichier = getFichierFromDocument(document, fichiers);

                return fichier === undefined || isRequiredDocumentInError(fichier);
            }).length;
            setMissingDocumentsCount(nb);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [documents, fichiers],
    );

    const getDownloadableDocumentsCount = useCallback(
        (): number =>
            documents.filter(
                (document: DossierDocument) =>
                    !isNoType(document?.document?.id as number) &&
                    isFormalisteDocumentType(document?.document?.type as number) &&
                    documentHasFichier(document, fichiers),
            ).length,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [documents, fichiers],
    );

    useEffect(() => {
        getDocumentStatuses().then(setStatuses);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getDossierDocuments(idDossier as unknown as number).then(setDocuments);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idDossier]);

    useEffect(() => {
        getDossierFichiers(idDossier as unknown as number).then(setFichiers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idDossier]);

    const contextValue = useMemo(
        () => ({
            missingDocumentsCount,
            statuses,
            setStatuses,
            getStatusLabel,
            documents,
            fichiers,
            addFichier,
            addMultipleFichiers,
            removeFichier,
            replaceFichier,
            getDownloadableDocumentsCount,
        }),
        [
            missingDocumentsCount,
            statuses,
            setStatuses,
            getStatusLabel,
            documents,
            fichiers,
            addFichier,
            addMultipleFichiers,
            removeFichier,
            replaceFichier,
            getDownloadableDocumentsCount,
        ],
    );

    return <DocumentContext.Provider value={contextValue}>{children}</DocumentContext.Provider>;
};

export default DocumentProvider;
