import React from 'react';
import { Outlet } from 'react-router-dom';
import {
    DossierActionProvider,
    DossierGroupModal,
    DossierStepProvider,
    CancelDossierModal,
    SuspendDossierModal,
} from '@europrocurement/l2d-modules';

const DossierRoot: React.FunctionComponent = function () {
    return (
        <DossierStepProvider>
            <DossierActionProvider>
                <Outlet />
                <DossierGroupModal />
                <CancelDossierModal />
                <SuspendDossierModal />
            </DossierActionProvider>
        </DossierStepProvider>
    );
};

export default DossierRoot;
