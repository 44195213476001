import { MenuEntry } from '@europrocurement/flexy-components';
import { dossiersBasePath } from '../pages/Dossiers';

const menuEntries: MenuEntry[] = [
    {
        title: 'Accueil',
        icon: 'house',
        href: '/accueil',
    },
    {
        title: 'Mes dossiers',
        icon: 'folders',
        href: `/${dossiersBasePath}`,
    },
    {
        title: 'Sociétés',
        icon: 'building-user',
        href: 'societes/',
    },
];

export default menuEntries;
