import { LazyBreadcrumb } from '@europrocurement/flexy-components';
import {
    DossierOpenSearch,
    DossierOpenSearchSelector,
    useFormaliteService,
} from '@europrocurement/l2d-domain';
import { DossierActionContext, DossierTable } from '@europrocurement/l2d-modules';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AnyAction } from '@reduxjs/toolkit';
import { dossiersBasePath } from '../../constants';
import { useDossierTable } from '../../hooks';

const ListeDossiers: React.FunctionComponent = function () {
    const navigate = useNavigate();
    const { dossiersUpdated } = useContext(DossierActionContext);
    const dispatch = useDispatch();
    const datasource: DataSource<DossierOpenSearch> = useSelector(DossierOpenSearchSelector);
    const { columns, filters, preFilters, rowActions } = useDossierTable();
    const { getOpenSearchDossiers } = useFormaliteService();

    const breadcrumbItems = [
        {
            link: '/accueil',
            icon: {
                icon: ['fasl', 'house'] as IconProp,
                sx: { display: 'flex', alignItems: 'center' },
            },
        },
        {
            link: '/dossiers',
            path: 'Mes dossiers',
        },
        'Mes dossiers',
    ];

    const handleRowClick = ({ id }: DossierOpenSearch) => {
        navigate(`/${dossiersBasePath}/${id}`);
    };

    useEffect(() => {
        if (dossiersUpdated.length) {
            setTimeout(() => {
                dispatch(getOpenSearchDossiers({}) as unknown as AnyAction);
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dossiersUpdated]);

    return (
        <>
            <LazyBreadcrumb
                path={breadcrumbItems}
                separator=" / "
                sx={{ marginBottom: '1.5em' }}
            />
            <DossierTable
                datasource={datasource}
                columns={columns}
                filters={filters}
                preFilters={preFilters}
                rowActions={rowActions}
                onRowClick={handleRowClick}
            />
        </>
    );
};

export default ListeDossiers;
