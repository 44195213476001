import React, { useContext, useEffect, useState } from 'react';
import { CardSection } from '@europrocurement/flexy-components';
import { castIriToId } from '@europrocurement/l2d-utils';
import { enqueueSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import {
    DocumentContext,
    DocumentPreviewContext,
    SelectedDossierContext,
} from '../../../providers';
import { DossierDocument, DossierFichier, useDossierDocuments } from '../../../hooks';
import { Document } from '../DocumentItem/DocumentItem';
import RequiredDocumentsHeading from '../RequiredDocumentsHeading/RequiredDocumentsHeading';
import RequiredDocumentsList from '../RequiredDocumentsList/RequiredDocumentsList';
import RequiredDocumentUploader from '../RequiredDocumentUploader/RequiredDocumentUploader';

export type DisplayedDocument = {
    id: number;
    fichierId?: number;
    uploadable: boolean;
    uploading: boolean;
    editable: boolean;
    showable: boolean;
    downloadable: boolean;
    removable: boolean;
    hasType: boolean;
} & Document;

const RequiredDocumentsTab: React.FunctionComponent = function () {
    const { dossier } = useContext(SelectedDossierContext);
    const {
        getStatusLabel,
        documents,
        fichiers,
        addFichier,
        addMultipleFichiers,
        removeFichier,
        replaceFichier,
    } = useContext(DocumentContext);
    const {
        getStatusIdentifier,
        getFichierFromDocument,
        uploadDossierFichier,
        uploadAdditionalDossierFichier,
        removeDossierFichier,
        downloadFichier,
        getFichierContent,
        isClientDocumentType,
        isNoType,
    } = useDossierDocuments();
    const { setDocumentPreview } = useContext(DocumentPreviewContext);
    const [listItems, setListItems] = useState<Array<DisplayedDocument>>([]);
    const [uploadingAdditionalDocument, setUploadingAdditionalDocument] = useState<boolean>(false);

    const toDisplayedDocument = (
        document: DossierDocument,
        fichier: DossierFichier | undefined,
        defaults: Record<string, boolean | string> | undefined = {},
    ): DisplayedDocument => {
        const statusId = fichier?.statut?.id
            ? fichier?.statut?.id
            : castIriToId(document.statut as string);
        const statusLabel = getStatusLabel(statusId) as string;
        const statusIdentifier = fichier ? getStatusIdentifier(statusId) : 'pending';
        const uploadable = !fichier || statusIdentifier === 'error';
        const editable = fichier !== undefined && uploadable;
        const removable = fichier !== undefined && statusIdentifier !== 'success';
        const downloadable = fichier !== undefined && statusIdentifier === 'success';
        let label = document.libelle as string;
        label = label.charAt(0).toUpperCase() + label.slice(1);

        return {
            id: document.id as number,
            fichierId: fichier ? fichier.id : undefined,
            label,
            fileName: fichier ? (fichier.fichier as string) : undefined,
            status: statusIdentifier,
            feedback: fichier ? statusLabel : undefined,
            uploading: false,
            uploadable,
            downloadable,
            showable: fichier !== undefined,
            editable,
            removable,
            hasType: true,
            ...defaults,
        };
    };

    useEffect(() => {
        const displayedDocuments = documents
            .filter(
                (document: DossierDocument) =>
                    !isNoType(document?.document?.id as number) &&
                    isClientDocumentType(document?.document?.type as number),
            )
            .map((document) => {
                const fichier = getFichierFromDocument(document, fichiers);

                return toDisplayedDocument(document, fichier);
            });

        const additionalFichiers = fichiers
            .filter(
                (fichier) =>
                    fichier?.typeDocDossier &&
                    isNoType(fichier.typeDocDossier?.document?.id as number) &&
                    isClientDocumentType(fichier.expediteur as unknown as number),
            )
            .map(
                (fichier): DisplayedDocument =>
                    toDisplayedDocument(fichier.typeDocDossier as DossierDocument, fichier, {
                        hasType: false,
                    }),
            );

        setListItems([...displayedDocuments, ...additionalFichiers]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documents, fichiers]);

    const countSuccessfull = listItems.reduce(
        (accumulator, doc) =>
            doc.status === 'success' && doc.hasType ? accumulator + 1 : accumulator,
        0,
    );

    const countTotal = listItems.reduce(
        (accumulator, doc) => (doc.hasType ? accumulator + 1 : accumulator),
        0,
    );

    const updateListItem = (query: Record<string, number>, payload: object) => {
        const items = listItems.map((item) => {
            const match = Object.keys(query).every(
                (key: 'id' | 'fichierId') => item[key] === query[key],
            );

            if (match) {
                return { ...item, ...payload };
            }

            return { ...item };
        });

        setListItems(items);
    };

    const handleAddFile = async (documentId: number, file: File, fichierId?: number) => {
        const document = documents.find((doc) => Number(doc.id) === documentId);

        if (!document) {
            enqueueSnackbar(<Typography>Une erreur est survenue</Typography>, { variant: 'error' });

            return;
        }

        const query: Record<string, number> =
            fichierId !== undefined ? { fichierId } : { id: documentId };
        updateListItem(query, { uploading: true, uploadable: false });
        let existingFichier = null;

        if (fichierId) {
            existingFichier = fichiers.find((f: DossierFichier) => Number(f.id) === fichierId);
        }

        if (undefined === existingFichier) {
            existingFichier = getFichierFromDocument(document, fichiers);
        }

        if (existingFichier) {
            removeDossierFichier(existingFichier.id as number);
        }

        try {
            const fichier = await uploadDossierFichier(
                dossier.id as number,
                `/formalite/types-documents/${document?.document?.id}` as string,
                file,
            );

            enqueueSnackbar(<Typography>Votre fichier a bien été ajouté</Typography>, {
                variant: 'success',
            });

            if (existingFichier) {
                replaceFichier(existingFichier, fichier);
            } else {
                addFichier(fichier);
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar(
                <Typography>Une erreur est survenue lors du transfert de voter fichier</Typography>,
                {
                    variant: 'error',
                },
            );
            updateListItem(query, { uploading: false });
        }
    };

    const handleRemoveFile = async (fichierId: number) => {
        const fichier = fichiers.find((f: DossierFichier) => Number(f.id) === fichierId);

        if (!fichier) {
            enqueueSnackbar(<Typography>Une erreur est survenue</Typography>, {
                variant: 'error',
            });

            return;
        }

        updateListItem({ fichierId }, { uploading: true });
        try {
            await removeDossierFichier(fichierId);
            removeFichier(fichier);
            enqueueSnackbar(<Typography>Votre fichier a bien été supprimé</Typography>, {
                variant: 'success',
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(<Typography>Une erreur est survenue</Typography>, {
                variant: 'error',
            });
        }
    };

    const handleDownload = async (fichierId: number) => {
        const fichier = fichiers.find((f: DossierFichier) => Number(f.id) === fichierId);

        if (!fichier) {
            enqueueSnackbar(<Typography>Une erreur est survenue</Typography>, { variant: 'error' });

            return;
        }

        updateListItem({ fichierId }, { uploading: true });

        try {
            await downloadFichier(fichier);
        } catch (err) {
            console.error(err);
            enqueueSnackbar(<Typography>Une erreur est survenue</Typography>, { variant: 'error' });
        } finally {
            updateListItem({ fichierId }, { uploading: false });
        }
    };

    const handleAdditionalUpload = async (file: File) => {
        const fichier = await uploadAdditionalDossierFichier(dossier.id as number, file);

        return fichier;
    };

    const handleAdditionalUploads = async (files: FileList) => {
        setUploadingAdditionalDocument(true);

        try {
            await Promise.all(Array.from(files).map((file) => handleAdditionalUpload(file))).then(
                (newFichiers) => {
                    addMultipleFichiers(newFichiers);
                    enqueueSnackbar(<Typography>Votre fichier a bien été ajouté</Typography>, {
                        variant: 'success',
                    });
                },
            );
        } catch (err) {
            console.error(err);
            enqueueSnackbar(
                <Typography>Une erreur est survenue lors du transfert de voter fichier</Typography>,
                {
                    variant: 'error',
                },
            );
        } finally {
            setUploadingAdditionalDocument(false);
        }
    };

    const handleShow = async (label: string, fichierId: number) => {
        updateListItem({ fichierId }, { uploading: true });

        const fichier = fichiers.find((f: DossierFichier) => Number(f.id) === fichierId);

        if (!fichier) {
            enqueueSnackbar(<Typography>Une erreur est survenue</Typography>, { variant: 'error' });

            return;
        }

        try {
            const file = await getFichierContent(fichierId);
            setDocumentPreview({
                fileName: label,
                content: file.data as unknown as Blob,
            });
        } catch (err) {
            console.error(err);
            enqueueSnackbar(<Typography>Une erreur est survenue</Typography>, { variant: 'error' });
        } finally {
            updateListItem({ fichierId }, { uploading: false });
        }
    };

    return (
        <CardSection
            header={
                <RequiredDocumentsHeading
                    currentCount={countSuccessfull}
                    totalCount={countTotal}
                />
            }
            sxContent={{
                padding: '0 32px 32px',
                display: 'flex',
                flexDirection: 'column',
                gap: '24px 0',
            }}
        >
            <RequiredDocumentsList
                documents={listItems}
                onUpload={handleAddFile}
                onDeletion={handleRemoveFile}
                onDownload={handleDownload}
                onShow={handleShow}
            />
            <RequiredDocumentUploader
                uploading={uploadingAdditionalDocument}
                onChange={handleAdditionalUploads}
            />
        </CardSection>
    );
};

export default RequiredDocumentsTab;
