import React, { PropsWithChildren, useMemo, useState } from 'react';
import DocumentPreviewContext, { DocumentPreview } from './DocumentPreviewContext';

const DocumentPreviewProvider: React.FunctionComponent<PropsWithChildren> = function ({
    children,
}) {
    const [documentPreview, setDocumentPreview] = useState<DocumentPreview | undefined>();

    const contextValue = useMemo(
        () => ({
            documentPreview,
            setDocumentPreview,
        }),
        [documentPreview, setDocumentPreview],
    );

    return (
        <DocumentPreviewContext.Provider value={contextValue}>
            {children}
        </DocumentPreviewContext.Provider>
    );
};

export default DocumentPreviewProvider;
