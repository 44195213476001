import React, { useContext } from 'react';
import { CountBadge, FlexyTabs, TabStructure } from '@europrocurement/flexy-components';
import { Box } from '@mui/material';
import { DocumentContext } from '../../../providers';
import InformationsTab from '../InformationsTab/InformationsTab';
import RequiredDocumentsTab from '../RequiredDocumentsTab/RequiredDocumentsTab';
import DownloadableDocumentsTab from '../DownloadableDocumentsTab/DownloadableDocumentsTab';
import BillingTab from '../BillingTab/BillingTab';

const PrescriberDossierBody: React.FunctionComponent = function () {
    const { missingDocumentsCount, getDownloadableDocumentsCount } = useContext(DocumentContext);
    const downloadableDocuments = getDownloadableDocumentsCount();

    const tabs: Array<TabStructure> = [
        {
            tabName: 'details',
            tabTitle: 'Détails du dossier',
            tabPanel: <InformationsTab />,
        },
        {
            tabName: 'required-document',
            tabTitle: (
                <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                    Documents requis{' '}
                    {missingDocumentsCount > 0 && (
                        <CountBadge
                            count={missingDocumentsCount}
                            color="error"
                            bgVariant="main"
                            contrastText
                        />
                    )}
                </Box>
            ),
            tabPanel: <RequiredDocumentsTab />,
        },
        {
            tabName: 'downloadable-document',
            tabTitle: (
                <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                    Documents remis{' '}
                    {downloadableDocuments > 0 && (
                        <CountBadge
                            count={downloadableDocuments}
                            color="primary"
                            bgVariant="light"
                        />
                    )}
                </Box>
            ),
            tabPanel: <DownloadableDocumentsTab />,
        },
        {
            tabName: 'billing',
            tabTitle: 'Facturation',
            tabPanel: <BillingTab />,
        },
    ];

    return (
        <FlexyTabs
            routerParameterName="dossier-detail"
            sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}
            tabs={tabs}
            scrollbar={false}
            indicatorColor="primary"
        />
    );
};

export default PrescriberDossierBody;
