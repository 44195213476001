import React, { useContext, useEffect, useState } from 'react';
import {
    FiltersDatatableList,
    PreFilterDatatableList,
    RowSelectionAction,
} from '@europrocurement/flexy-datatable';
import {
    DossierActionContext,
    DossierClient,
    DossierCollection,
    DossierFormaliste,
    DossierFormalite as DossierFormaliteFilter,
    DossierObjectGroup,
    DossierSocieteReference,
    useDossierTable as useDefaultDossierTable,
    useDossierClient,
    useDossierCollection,
    useDossierFormaliste,
    useDossierFormalite,
    useDossierNumero,
    useDossierObjectGroup,
    useDossierSocieteReference,
    useDossierStatut,
} from '@europrocurement/l2d-modules';
import { DossierOpenSearch } from '@europrocurement/l2d-domain';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import ActionMenu from '../views/liste/ActionMenu/ActionMenu';

const useDossierTable = () => {
    const { columns } = useDefaultDossierTable();
    const { searchDossierStatut } = useDossierStatut();
    const { searchDossierFormalite } = useDossierFormalite();
    const { searchDossierObjectGroup } = useDossierObjectGroup();
    const { searchDossierNumero } = useDossierNumero();
    const { searchDossierCollection } = useDossierCollection();
    const { searchDossierSocieteReference } = useDossierSocieteReference();
    const { searchDossierClient } = useDossierClient();
    const { searchDossierFormaliste } = useDossierFormaliste();
    const { triggerAction } = useContext(DossierActionContext);
    const [preFilters, setPreFilters] = useState<PreFilterDatatableList>();

    columns.push({
        label: 'Actions',
        displayLabel: false,
        render: (dossier) => <ActionMenu dossier={dossier} />,
        isDisplayed: true,
        sortable: false,
        sx: {
            display: {
                xs: 'none',
                sm: 'none',
                md: 'table-cell',
                lg: 'table-cell',
                xl: 'table-cell',
            },
        },
    });

    const filters: FiltersDatatableList = [
        {
            label: 'Mes groupes',
            field: 'groupId',
            type: 'selectApi',
            icon: 'folders',
            searchFunction: searchDossierObjectGroup,
            renderItem: (item: DossierObjectGroup) => item.label as string,
            renderIcon: (item: DossierObjectGroup, defaultIcon) => {
                const icon = item?.icon || defaultIcon;
                const color = item?.color;

                return (
                    <FaOptionIcon
                        sx={{
                            fontSize: '12px',
                            lineHeight: '0',
                            color: color ? `${color}.main` : 'inherit',
                        }}
                        icon={['fasl', icon as IconName]}
                    />
                );
            },
            valueMap: (item: DossierObjectGroup) => item.id as unknown as string,
        },
        {
            label: 'Numéro de dossier',
            field: 'numero',
            type: 'selectApi',
            icon: 'hashtag',
            searchFunction: searchDossierNumero,
            renderItem: (item: DossierCollection) => item.numero as string,
            valueMap: (item: DossierCollection) => item.numero as string,
        },
        {
            label: 'Commandes',
            field: 'collectionId',
            type: 'selectApi',
            icon: 'file-lines',
            searchFunction: searchDossierCollection,
            renderItem: (item: DossierCollection) => item.numero as string,
            valueMap: (item: DossierCollection) => item.id,
        },
        {
            label: 'Référence client',
            field: 'societeReference',
            type: 'selectApi',
            icon: 'user',
            searchFunction: searchDossierSocieteReference,
            renderItem: (item: DossierSocieteReference) => item.reference as string,
            valueMap: (item: DossierSocieteReference) => item.reference as string,
        },
        {
            type: 'selectMultipleApi',
            label: 'Clients',
            field: 'clientId',
            icon: 'building-user',
            placeholder: 'Rechercher un client',
            title: 'Client',
            getItems: searchDossierClient,
            getItemLabel: (item: DossierClient) => item.libelle as string,
            getItemValue: (item: DossierClient) => item.id as unknown as string,
            getGroupLabel: (item: DossierClient) => item.prescripteurLibelle as string,
            getGroupValue: (item: DossierClient) => item.prescripteurId as unknown as string,
        },
        {
            label: 'Formalité',
            field: 'formaliteId',
            type: 'selectMultipleApi',
            icon: 'gavel',
            placeholder: 'Rechercher une formalite',
            title: 'Formalités',
            getItems: searchDossierFormalite,
            getItemLabel: (item: DossierFormaliteFilter) => item.libelle as string,
            getItemValue: (item: DossierFormaliteFilter) => item.id as unknown as string,
            getGroupLabel: (item: DossierFormaliteFilter) => item.groupementLibelle as string,
            getGroupValue: (item: DossierFormaliteFilter) => item.groupementId as unknown as string,
        },
        {
            label: 'Formaliste',
            field: 'formalisteId',
            icon: 'circle-user',
            type: 'selectApi',
            searchFunction: searchDossierFormaliste,
            renderItem: (item: DossierFormaliste) => item.nomComplet as string,
            valueMap: (item: DossierFormaliste) => item.id,
        },
        {
            type: 'dateRange',
            label: 'Date de création',
            field: 'dateCreation',
            icon: 'calendar',
            displayFormat: (startDate: Date, endDate: Date) =>
                `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`,
        },
    ];
    const rowActions: RowSelectionAction[] = [
        {
            icon: 'fa-folders',
            label: 'Déplacer dans un groupe',
            onClick: (items: Array<DossierOpenSearch>) => triggerAction('manageGroups', items),
        },
    ];

    useEffect(() => {
        searchDossierStatut().then((statuses) => {
            const pf: PreFilterDatatableList = {
                label: 'Filtrer par statuts',
                items: [
                    {
                        label: 'Tout',
                        filters: [],
                    },
                    {
                        label: 'Urgent',
                        filters: [{ field: 'critical', value: true }],
                    },
                ],
            };

            statuses.forEach((status) => {
                pf.items.push({
                    label: status.libelle as string,
                    filters: [{ field: 'statutId', value: status.id as unknown as string }],
                });
            });

            setPreFilters(pf);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { columns, filters, preFilters, rowActions };
};

export default useDossierTable;
