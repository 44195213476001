import { Box, Button, useTheme } from '@mui/material';
import React from 'react';
import { FaOptionIcon } from '@europrocurement/l2d-icons';

export type DocumentPreviewFooterProps = {
    onDownload: () => void;
};

const DocumentPreviewFooter: React.FunctionComponent<DocumentPreviewFooterProps> = function (
    props,
) {
    const { onDownload: handleDownload } = props;
    const { palette } = useTheme();

    const footerSx = {
        marginTop: 'auto',
        width: '100%',
        padding: '40px 30px',
    };

    const footerButtonSx = {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        color: palette.grey[600],
        fontSize: '13px',
        lineHieght: '21px',
    };

    const footerIconSx = {};

    return (
        <Box sx={footerSx}>
            <Button
                sx={footerButtonSx}
                onClick={handleDownload}
                data-testid="download-button"
            >
                <FaOptionIcon
                    sx={footerIconSx}
                    icon={['fasl', 'arrow-down-to-line']}
                />
                Télécharger
            </Button>
        </Box>
    );
};

export default DocumentPreviewFooter;
