import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { PdfViewer } from '@europrocurement/flexy-components';
import { useDownload } from '@europrocurement/l2d-modules/hooks';
import { DocumentPreviewContext } from '../../../providers';
import DocumentPreviewHeader from '../DocumentPreviewHeader/DocumentPreviewHeader';
import DocumentPreviewFooter from '../DocumentPreviewFooter/DocumentPreviewFooter';

const DocumentPreview: React.FunctionComponent = function () {
    const { palette, breakpoints } = useTheme();
    const downMd = useMediaQuery(breakpoints.down('md'));
    const { documentPreview, setDocumentPreview } = useContext(DocumentPreviewContext);
    const { download } = useDownload();

    if (!documentPreview) {
        return null;
    }

    const { fileName, content } = documentPreview;

    const handleClose = () => {
        setDocumentPreview(undefined);
    };

    const handleDownload = () => {
        download(fileName, content);
    };

    const blob = new Blob([content as unknown as string], {
        type: 'application/octet-stream',
    });
    const fileURL = URL.createObjectURL(blob);

    const wrapperSx = {
        position: 'fixed',
        top: '0',
        right: '0',
        maxWidth: downMd ? '100%' : '50%',
        width: '100%',
        height: '100%',
        background: palette.background.paper,
        boxShadow: `1px 1px 15px 0px ${palette.grey[200]}`,
        borderRadius: '12px',
        zIndex: 1150,
        display: 'flex',
        flexDirection: 'column',
    };

    const contentSx = {
        margin: '0 30px',
        border: `solid 1px ${palette.grey[300]}`,
        boxShadow: `0px 2px 2.5px 0px ${palette.grey[300]}`,
        height: '80%',
        borderRadius: '6px',
    };

    return (
        <Box sx={wrapperSx}>
            <DocumentPreviewHeader
                fileName={fileName}
                onClose={handleClose}
            />
            <Box sx={contentSx}>
                <PdfViewer
                    title={fileName}
                    pdfUrl={fileURL}
                    showTitle={false}
                />
            </Box>
            <DocumentPreviewFooter onDownload={handleDownload} />
        </Box>
    );
};

export default DocumentPreview;
