import React from 'react';
import { Box } from '@mui/material';
import InvoiceItem, { InvoiceItemProps } from '../InvoiceItem/InvoiceItem';

export type InvoiceListProps = {
    items: Array<InvoiceItemProps>;
};

const InvoiceList: React.FunctionComponent<InvoiceListProps> = function (props) {
    const { items } = props;
    const wrapperSx = {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px 0',
    };

    return (
        <Box
            sx={wrapperSx}
            data-testid="invoice-list"
        >
            {items.map((item) => (
                <InvoiceItem
                    key={item.label}
                    label={item.label}
                    status={item.status}
                    feedback={item.feedback}
                    id={item.id}
                    onDownload={item.onDownload}
                    onShow={item.onShow}
                />
            ))}
        </Box>
    );
};

export default InvoiceList;
