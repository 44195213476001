import React from 'react';
import { Avatar, lighten, useTheme } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';

export type DocumentIconProps = {
    icon: 'file' | 'file-pdf' | 'file-jpg' | 'file-doc' | 'file-xls' | 'file-arrow-down';
    color?: 'primary' | 'secondary' | 'success' | 'error';
    transparentBackground?: boolean;
};

const DocumentIcon: React.FunctionComponent<DocumentIconProps> = function (props) {
    const { icon, color = 'secondary', transparentBackground = false } = props;
    const { palette } = useTheme();
    const baseColor = palette[color].main;

    const avatarSx = {
        display: 'flex',
        borderRadius: '8px',
        backgroundColor: transparentBackground ? 'transparent' : lighten(baseColor, 0.92),
        minHeight: '36px',
        minWidth: '36px',
    };

    const avatarIconSx = {
        fontSize: '16px',
        lineHeight: '16px',
        fontWeight: '400',
        color: palette[color].main,
    };

    return (
        <Avatar
            data-testid="document-icon"
            sx={avatarSx}
        >
            <FaOptionIcon
                icon={['fasl', icon]}
                sx={avatarIconSx}
            />
        </Avatar>
    );
};

export default DocumentIcon;
