const useDownload = () => {
    const download = (fileName: string, fileContent: Blob) => {
        const blob = new Blob([fileContent], {
            type: 'application/octet-stream',
        });
        const fileURL = URL.createObjectURL(blob);
        const downloadLink = window.document.createElement('a');
        downloadLink.target = '_blank';
        downloadLink.href = fileURL;
        downloadLink.download = fileName;
        window.document.body.appendChild(downloadLink);
        downloadLink.click();
        URL.revokeObjectURL(fileURL);
        window.document.body.removeChild(downloadLink);
    };

    return { download };
};

export default useDownload;
