import React, { ChangeEvent, DragEvent, LegacyRef, PropsWithChildren, useId } from 'react';

export type UploadableDocumentProps = {
    accept?: string;
    maxMbSize?: number;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    onDragEnter: () => void;
    onDragLeave: () => void;
    onValidationError: (err: Error) => void;
    onChange: (files: FileList) => void;
    fileInputRef: LegacyRef<HTMLLabelElement>;
};

const UploadableDocument: React.FunctionComponent<PropsWithChildren<UploadableDocumentProps>> =
    function (props) {
        const {
            children,
            accept = 'application/pdf',
            maxMbSize = 10,
            onMouseEnter,
            onMouseLeave,
            onDragEnter,
            onDragLeave,
            onValidationError,
            onChange,
            fileInputRef,
        } = props;
        const id = useId();

        const stopDefaults = (e: ChangeEvent | DragEvent | MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
        };

        const validateFiles = async (files: FileList) =>
            new Promise((resolve, reject) => {
                Array.from(files).forEach((file) => {
                    if (maxMbSize !== undefined && file.size > maxMbSize * 1000000) {
                        reject(new Error('Fichier trop lourd'));
                    }
                    if (accept !== undefined && !accept.split(',').includes(file.type)) {
                        reject(new Error('Format de fichier non compatible'));
                    }
                });

                resolve(files);
            });

        const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
            stopDefaults(event);
            validateFiles(event.target.files || new FileList()).then(onChange, onValidationError);
        };
        const handleMouseEnter = () => onMouseEnter();
        const handleMouseLeave = () => onMouseLeave();
        const handleDragEnter = () => onDragEnter();
        const handleDragLeave = () => onDragLeave();

        const handleDrop = (event: DragEvent<HTMLElement>) => {
            stopDefaults(event);
            onMouseLeave();
            onDragLeave();

            validateFiles(event.dataTransfer.files || new FileList()).then(
                onChange,
                onValidationError,
            );
        };

        const dragEvents = {
            onMouseEnter: (e: MouseEvent) => {
                stopDefaults(e);
                handleMouseEnter();
            },
            onMouseLeave: (e: MouseEvent) => {
                stopDefaults(e);
                handleMouseLeave();
            },
            onDragEnter: (e: DragEvent) => {
                stopDefaults(e);
                handleDragEnter();
            },
            onDragLeave: (e: DragEvent) => {
                stopDefaults(e);
                handleDragLeave();
            },
            onDragOver: stopDefaults,
            onDrop: (e: DragEvent<HTMLElement>) => {
                handleDrop(e);
            },
        };

        return (
            <label
                htmlFor={id}
                ref={fileInputRef}
                {...dragEvents}
            >
                <input
                    data-testid="uploadable-document-file-input"
                    id={id}
                    type="file"
                    onChange={handleChange}
                    accept={accept}
                    size={maxMbSize * 1000000}
                    multiple
                    hidden
                />

                {children}
            </label>
        );
    };

export default UploadableDocument;
