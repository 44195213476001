import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { useForm } from 'react-hook-form';

export type JustificationModalProps = {
    title: string;
    description: string;
    onConfirm: (message: string) => void;
    onCancel: () => void;
};

const JustificationModal: React.FunctionComponent<JustificationModalProps> = function (props) {
    const { title, description, onConfirm, onCancel: handleCancel } = props;
    const { palette } = useTheme();

    const handleSubmit = (formData: { message: string }) => {
        onConfirm(formData.message);
    };

    const formStructure: FormStructure[] = [
        {
            type: 'textarea',
            name: 'message',
            inputlabel: 'Message',
            rules: {
                required: 'Vous devez renseigner un message',
            },
            inputProps: {
                multiline: true,
                maxRows: Infinity,
            },
        },
    ];

    const formContext = useForm({
        mode: 'onTouched',
    });

    const formProps = {
        formContext,
        formObject: {},
        onSubmit: handleSubmit,
        submitButton: {
            displayed: false,
        },
    };

    return (
        <Box sx={{ maxWidth: '450px', display: 'flex', flexDirection: 'column', gap: '16px 0' }}>
            <Typography variant="l2dh6">{title}</Typography>
            <Typography
                component="span"
                variant="l2dbodyxs"
            >
                {description}
            </Typography>
            <FlexyForm
                formStructure={formStructure}
                {...formProps}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    variant="text"
                    onClick={handleCancel}
                    sx={{ color: palette.primary.dark }}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    onClick={formContext.handleSubmit(handleSubmit)}
                    sx={{ padding: '16px 24px', background: palette.primary.dark }}
                >
                    Confirmer
                </Button>
            </Box>
        </Box>
    );
};

export default JustificationModal;
