import { Status, useFormaliteService } from '@europrocurement/l2d-domain';
import {
    FichiersDossierJsonldDocumentfileRead,
    TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { useDownload } from '@europrocurement/l2d-modules/hooks';

export type DossierDocument = TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead;
export type DossierFichier = FichiersDossierJsonldDocumentfileRead;

const STATUS_TYPE_DOCUMENT = 2;
const STATUS_VALID = 9;
const STATUS_REFUSED = 10;
const TYPE_NO_TYPE = 66;
const TYPE_DOC_CLIENT = 1;
const TYPE_DOC_FORMALISTE = 3;

const errorStatuses: Array<number> = [STATUS_REFUSED];
const successStatuses: Array<number> = [STATUS_VALID];

const useDossierDocuments = () => {
    const { documentsApi, statusApi, fichiersDossierApi } = useFormaliteService();
    const { download } = useDownload();

    const getDossierDocuments = (dossierId: number) =>
        documentsApi
            .apiDossiersDossierTypesDocDossierGetCollection({
                dossier: dossierId as unknown as string,
            })
            .then((res) => res.data['hydra:member'] as Array<DossierDocument>);

    const getDocumentStatuses = () =>
        statusApi
            .apiStatutsGetCollection({ type: STATUS_TYPE_DOCUMENT })
            .then((res) => res.data['hydra:member'] as Array<Status>);

    const getDossierFichiers = (dossierId: number) =>
        fichiersDossierApi
            .apiDossiersDossierFichiersGetCollection({
                dossier: dossierId as unknown as string,
            })
            .then((res) => res.data['hydra:member'] as Array<DossierFichier>);

    const uploadDossierFichier = (dossierId: number, typeDoc: string, file: File) =>
        fichiersDossierApi
            .apiDossiersDossierFichiersPost({
                dossier: dossierId as unknown as string,
                typeDoc,
                file,
            })
            .then((res) => res.data as DossierFichier);

    const uploadAdditionalDossierFichier = (dossierId: number, file: File) =>
        fichiersDossierApi
            .apiDossiersDossierFichiersPost({
                dossier: dossierId as unknown as string,
                file,
            })
            .then((res) => res.data as DossierFichier);

    const removeDossierFichier = (fichierId: number) =>
        fichiersDossierApi.apiFichiersIdDelete({ id: fichierId as unknown as string });

    const getStatusIdentifier = (statusId: number): 'pending' | 'error' | 'success' => {
        if (errorStatuses.includes(statusId)) {
            return 'error';
        }

        if (successStatuses.includes(statusId)) {
            return 'success';
        }

        return 'pending';
    };

    const getFichierFromDocument = (
        document: DossierDocument,
        fichiers: Array<DossierFichier>,
    ): DossierFichier | undefined =>
        fichiers.find((f) => Number(f.typeDocDossier?.id) === Number(document?.id));

    const documentHasFichier = (
        document: DossierDocument,
        fichiers: Array<DossierFichier>,
    ): DossierFichier | undefined =>
        fichiers.find((f) => Number(f.typeDocDossier?.id) === Number(document?.id));

    const getFichierContent = (fichierId: number) =>
        fichiersDossierApi.apiFichiersIdFileGet(
            { id: fichierId as unknown as string },
            { responseType: 'blob' },
        );

    const downloadFichier = async (fichier: DossierFichier) => {
        const content = await getFichierContent(fichier.id as number);
        console.log(content);
        download(fichier.fichier as string, content.data as unknown as Blob);
    };

    const isClientDocumentType = (type: number) => type === TYPE_DOC_CLIENT;

    const isFormalisteDocumentType = (type: number) => type === TYPE_DOC_FORMALISTE;

    const isNoType = (documentTypeId: number) => documentTypeId === TYPE_NO_TYPE;

    return {
        getDossierDocuments,
        getDossierFichiers,
        getDocumentStatuses,
        uploadDossierFichier,
        uploadAdditionalDossierFichier,
        removeDossierFichier,
        getStatusIdentifier,
        getFichierFromDocument,
        documentHasFichier,
        downloadFichier,
        getFichierContent,
        isClientDocumentType,
        isFormalisteDocumentType,
        isNoType,
    };
};

export default useDossierDocuments;
