import React, { ReactNode } from 'react';
import { IconButton, Tooltip } from '@mui/material';

export type DocumentAction = {
    title: string;
    content: ReactNode;
    onClick: () => void;
};

export type DocumentActionsProps = {
    actions: Array<DocumentAction>;
};

const DocumentActions: React.FunctionComponent<DocumentActionsProps> = function (props) {
    const { actions } = props;
    const actionButtonIconSx = {
        display: 'flex',
        padding: '0',
        justifyContent: 'center',
        alignItems: 'center',
        width: '25px',
        height: '25px',
    };

    return (
        <>
            {actions.map((action) => (
                <Tooltip
                    key={action.title}
                    title={action.title}
                >
                    <IconButton
                        sx={actionButtonIconSx}
                        onClick={action.onClick}
                    >
                        {action.content}
                    </IconButton>
                </Tooltip>
            ))}
        </>
    );
};

export default DocumentActions;
