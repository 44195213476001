import {
    Devis,
    FactureFormalite,
    useFacturationService,
    useSociete,
} from '@europrocurement/l2d-domain';
import { FactureJsonldFactureReadPdf } from '@europrocurement/l2d-domain/openApi/ApiFacturation';

export type PdfMetadata = FactureJsonldFactureReadPdf;

const useBilling = () => {
    const xIdSociete = useSociete();
    const { devisApi, factureApi } = useFacturationService();
    let getEstimatesTimeout: number | undefined;
    let getInvoicesTimeout: number | undefined;
    let getInvoicePdfMetadataTimeout: number | undefined;

    const getEstimates = (idDossier: number): Promise<Array<Devis>> =>
        new Promise((resolve, reject) => {
            clearTimeout(getEstimatesTimeout);
            const requestParameters = { xIdSociete, dossier: idDossier as unknown as string };

            getEstimatesTimeout = setTimeout(() => {
                devisApi
                    .apiDossiersDossierDevisGetCollection(requestParameters)
                    .then((res) => resolve(res.data['hydra:member'] as Array<Devis>))
                    .catch(() => reject());
            }, 300);
        });

    const getInvoices = (idDossier: number): Promise<Array<FactureFormalite>> =>
        new Promise((resolve, reject) => {
            clearTimeout(getInvoicesTimeout);
            const requestParameters = { xIdSociete, dossier: idDossier as unknown as string };

            getInvoicesTimeout = setTimeout(() => {
                factureApi
                    .apiDossiersDossierFacturesGetCollection(requestParameters)
                    .then((res) => resolve(res.data['hydra:member'] as Array<FactureFormalite>))
                    .catch(() => reject());
            }, 300);
        });

    const getInvoicePdf = (id: number): Promise<PdfMetadata> =>
        new Promise((resolve, reject) => {
            clearTimeout(getInvoicePdfMetadataTimeout);
            const requestParameters = { xIdSociete, id: id as unknown as string };

            getInvoicePdfMetadataTimeout = setTimeout(() => {
                factureApi
                    .apiFacturesIdPdfGet(requestParameters)
                    .then((res) => resolve(res.data as PdfMetadata))
                    .catch(() => reject());
            }, 300);
        });

    const getEstimateLabel = (estimate: Devis): string => `Devis ${estimate.numero}`;

    const getInvoiceLabel = (invoice: FactureFormalite): string => {
        const totalTtc = invoice.totalTtc as unknown as number;

        if (totalTtc < 0) {
            return `Avoir ${invoice.numero}`;
        }

        return `Facture ${invoice.numero}`;
    };

    const canShowInvoice = (invoice: FactureFormalite): boolean => !invoice.factureEnPreparation;

    const isInvoicePaid = (invoice: FactureFormalite): boolean =>
        invoice.montantPaiementRecu !== undefined &&
        invoice.totalTtc !== undefined &&
        (Number(invoice.totalTtc) <= 0 ||
            Number(invoice.montantPaiementRecu) >= Number(invoice.totalTtc));

    const getInvoiceRemainingAmount = (invoice: FactureFormalite): number => {
        const totalTtc = invoice.totalTtc as unknown as number;
        const alreadyPaid = invoice.montantPaiementRecu as unknown as number;

        if (totalTtc < 0) {
            return 0;
        }

        return totalTtc - alreadyPaid;
    };

    const downloadInvoice = (id: number): Promise<Blob> => {
        const resolver = async (resolve: (content: Promise<Blob>) => void, reject: () => void) => {
            try {
                const metadata = await getInvoicePdf(id);

                if (!metadata) {
                    throw new Error('Unable to retrieve file metadata');
                }

                const response = await fetch(metadata.pdf as string);
                const content = response.blob();

                return resolve(content);
            } catch (err) {
                console.error(err);

                return reject();
            }
        };

        return new Promise(resolver);
    };

    const canUpdateBillingInfos = (invoices: Array<FactureFormalite>): boolean =>
        invoices.find((invoice) => ((invoice?.totalTtc || 0) as number) > 0) === undefined;

    return {
        getEstimates,
        getInvoices,
        getEstimateLabel,
        getInvoiceLabel,
        canShowInvoice,
        isInvoicePaid,
        getInvoiceRemainingAmount,
        downloadInvoice,
        canUpdateBillingInfos,
    };
};

export default useBilling;
