import React from 'react';
import { List, ListItem } from '@mui/material';
import DownloadableDocumentItem, {
    DisplayedDownloadableDocument,
} from '../DownloadableDocumentItem/DownloadableDocumentItem';
import NoResult from '../NoResult/NoResult';

export type DownloadableDocumentsListProps = {
    documents: Array<DisplayedDownloadableDocument>;
    onDownload: (fichierId: number) => void;
};

const DownloadableDocumentsList: React.FunctionComponent<DownloadableDocumentsListProps> =
    function (props) {
        const { documents, onDownload: handleDownload } = props;

        if (!documents.length) {
            return (
                <NoResult
                    title="Pas encore de documents"
                    description="Les fichiers partagés seront affichés ici dès leur réception"
                />
            );
        }

        const listSx = {
            padding: '0',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px 0',
        };

        const listItemSx = {
            padding: '0',
            display: 'block',
        };

        const items = documents.map((document) => (
            <ListItem
                key={document.fichierId}
                sx={listItemSx}
            >
                <DownloadableDocumentItem
                    fileName={document.fileName}
                    onDownload={
                        document.fichierId
                            ? () => handleDownload(document.fichierId as number)
                            : () => {}
                    }
                />
            </ListItem>
        ));

        return (
            <List
                data-testid="downloadable-documents-list"
                sx={listSx}
            >
                {items}
            </List>
        );
    };

export default DownloadableDocumentsList;
