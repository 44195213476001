import React, { LegacyRef, useRef, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import {
    ConfirmationModal,
    ConfirmationModalProps,
    useModal,
} from '@europrocurement/flexy-components';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { useFileUploadProps } from '@europrocurement/l2d-modules/modules/ParcoursFormalite/hooks/useFileUploadProps';
import DocumentItem, { Document } from '../DocumentItem/DocumentItem';
import UploadableDocument from '../UploadableDocument/UploadableDocument';
import { DocumentAction } from '../DocumentActions/DocumentActions';

export type RequiredDocumentItemProps = {
    onChange: (files: FileList) => void;
    onDeletion: () => void;
    onDownload: () => void;
    onShow: () => void;
    uploadable: boolean;
    uploading: boolean;
    editable: boolean;
    showable: boolean;
    downloadable: boolean;
    removable: boolean;
} & Document;

const RequiredDocumentItem: React.FunctionComponent<RequiredDocumentItemProps> = function (props) {
    const {
        onChange: handleChange,
        onDeletion: handleDeletion,
        onDownload: handleDownload,
        onShow: handleShow,
        uploadable,
        uploading,
        editable,
        showable,
        downloadable,
        removable,
        label,
        fileName,
        type,
        status,
        feedback,
    } = props;
    const { palette } = useTheme();
    const [isMouseHover, setIsMouseHover] = useState<number>(0);
    const [isDragHover, setIsDragHover] = useState<number>(0);
    const fileInputRef: LegacyRef<HTMLLabelElement> = useRef(null);
    const { accept, maxMbSize } = useFileUploadProps();

    const handleMouseEnter = () => setIsMouseHover((val) => val + 1);
    const handleMouseLeave = () => setIsMouseHover((val) => val - 1);
    const handleDragEnter = () => setIsDragHover((val) => val + 1);
    const handleDragLeave = () => setIsDragHover((val) => val - 1);
    const handleValidationError = (err: Error) => {
        enqueueSnackbar(<Typography>{err.message}</Typography>, { variant: 'error' });
    };

    const actions: Array<DocumentAction> = [];
    const { modalActions } = useModal();
    const modalProps: ConfirmationModalProps = {
        messages: {
            question: 'Supprimer ce document ?',
            detail: {
                message: 'Êtes vous sur de vouloir supprimer ce document ?',
                severity: 'info',
            },
        },
        actionOnValidation: () => {
            modalActions.close();
            handleDeletion();
        },
        actionOnCancellation: () => {
            modalActions.close();
        },
        color: palette.warning,
    };

    const actionIconSx = {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '16px',
    };

    if (showable) {
        actions.push({
            title: 'Afficher le document',
            content: (
                <FaOptionIcon
                    icon={['fasl', 'eye']}
                    color="text.secondary"
                    sx={actionIconSx}
                />
            ),
            onClick: () => {
                handleShow();
            },
        });
    }

    if (downloadable) {
        actions.push({
            title: 'Télécharger le document',
            content: (
                <FaOptionIcon
                    icon={['fasl', 'arrow-down-to-line']}
                    color="text.secondary"
                    sx={actionIconSx}
                />
            ),
            onClick: () => {
                handleDownload();
            },
        });
    }

    if (editable) {
        actions.push({
            title: 'Modifier le document',
            content: (
                <FaOptionIcon
                    icon={['fasl', 'pen']}
                    color="text.secondary"
                    sx={actionIconSx}
                />
            ),
            onClick: () => {
                fileInputRef.current?.click();
            },
        });
    }

    if (removable) {
        actions.push({
            title: 'Supprimer le document',
            content: (
                <FaOptionIcon
                    icon={['fasl', 'trash-can']}
                    color="text.secondary"
                    sx={actionIconSx}
                />
            ),
            onClick: () => {
                modalActions.call(<ConfirmationModal {...modalProps} />);
            },
        });
    }

    const item = (
        <DocumentItem
            label={label as string}
            fileName={fileName}
            type={type}
            status={status}
            feedback={feedback}
            hover={isMouseHover > 0}
            highlight={isDragHover > 0}
            uploadable={uploadable}
            uploading={uploading}
            fileInputRef={fileInputRef}
            actions={actions}
        />
    );

    if (!uploadable) {
        return (
            <Box
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {item}
            </Box>
        );
    }

    return (
        <UploadableDocument
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onValidationError={handleValidationError}
            onChange={handleChange}
            accept={accept}
            maxMbSize={maxMbSize}
            fileInputRef={fileInputRef}
        >
            {item}
        </UploadableDocument>
    );
};

export default RequiredDocumentItem;
