import React from 'react';
import { CardHeader } from '@europrocurement/flexy-components';
import { Box, Typography, useTheme } from '@mui/material';

export type RequiredDocumentsHeadingProps = {
    currentCount: number;
    totalCount: number;
};

const RequiredDocumentsHeading: React.FunctionComponent<RequiredDocumentsHeadingProps> = function (
    props,
) {
    const { currentCount, totalCount } = props;
    const { palette } = useTheme();

    return (
        <CardHeader
            title="Documents requis"
            titleSx={{
                fontSize: '20px',
                lineHeight: '28px',
            }}
            border={false}
        >
            <Box>
                {totalCount > 0 && (
                    <Typography
                        component="p"
                        variant="l2dbodyxs"
                        sx={{ color: palette.grey[500] }}
                    >
                        {currentCount}/{totalCount} documents
                    </Typography>
                )}
                <Typography
                    component="p"
                    variant="l2dbodyxs"
                    sx={{ marginY: '8px', color: palette.grey[500] }}
                >
                    Formats : .pdf .doc .jpg .xls - Poids maximum : 10Mo
                </Typography>
            </Box>
        </CardHeader>
    );
};

export default RequiredDocumentsHeading;
