import React, { LegacyRef, useRef, useState } from 'react';
import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useFileUploadProps } from '@europrocurement/l2d-modules/modules/ParcoursFormalite/hooks/useFileUploadProps';
import UploadButton from '../UploadButton/UploadButton';
import DocumentIcon from '../DocumentIcon/DocumentIcon';
import UploadableDocument from '../UploadableDocument/UploadableDocument';

export type RequiredDocumentUploaderProps = {
    uploading: boolean;
    onChange: (files: FileList) => void;
};

const RequiredDocumentUploader: React.FunctionComponent<RequiredDocumentUploaderProps> = function (
    props,
) {
    const { uploading, onChange: handleChange } = props;
    const { palette, breakpoints } = useTheme();
    const downMd = useMediaQuery(breakpoints.down('md'));
    const { accept, maxMbSize } = useFileUploadProps();
    const fileInputRef: LegacyRef<HTMLLabelElement> = useRef(null);
    const [isMouseHover, setIsMouseHover] = useState<number>(0);
    const [isDragHover, setIsDragHover] = useState<number>(0);

    const handleMouseEnter = () => setIsMouseHover((val) => val + 1);
    const handleMouseLeave = () => setIsMouseHover((val) => val - 1);
    const handleDragEnter = () => setIsDragHover((val) => val + 1);
    const handleDragLeave = () => setIsDragHover((val) => val - 1);
    const handleValidationError = (err: Error) => {
        enqueueSnackbar(<Typography>{err.message}</Typography>, { variant: 'error' });
    };

    const wrapperSx = {
        display: 'flex',
        gap: '0 8px',
        alignItems: 'center',
        background: isDragHover ? palette.grey[100] : 'none',
        border: `dashed 1px ${palette.grey[300]}`,
        borderRadius: '12px',
        padding: '8px',
        transition: '0.1s ease',
        cursor: uploading ? 'normal' : 'pointer',
        boxShadow: isMouseHover ? `0px 2px 2.5px 0px ${palette.grey[200]}` : 'none',
    };

    const avatarWrapperSx = { padding: '10px 8px' };

    const textSx = {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '17px',
    };
    const uploadWidget = (
        <Box
            sx={wrapperSx}
            data-testid="required-document-uploader"
        >
            <Box sx={avatarWrapperSx}>
                <DocumentIcon
                    icon="file-arrow-down"
                    transparentBackground
                />
            </Box>
            <Typography sx={textSx}>Ajouter un document complémentaire au dossier</Typography>
            <Box sx={{ marginLeft: 'auto', padding: '0 8px' }}>
                {uploading && <CircularProgress size="16px" />}
                {!downMd && !uploading && <UploadButton fileInputRef={fileInputRef} />}
            </Box>
        </Box>
    );

    if (uploading) {
        return uploadWidget;
    }

    return (
        <UploadableDocument
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onValidationError={handleValidationError}
            onChange={handleChange}
            accept={accept}
            maxMbSize={maxMbSize}
            fileInputRef={fileInputRef}
        >
            {uploadWidget}
        </UploadableDocument>
    );
};

export default RequiredDocumentUploader;
