import React, { LegacyRef } from 'react';
import { Box, CircularProgress, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { InformationChip } from '@europrocurement/flexy-components';
import DocumentIcon from '../DocumentIcon/DocumentIcon';
import UploadButton from '../UploadButton/UploadButton';
import DocumentActions, { DocumentAction } from '../DocumentActions/DocumentActions';

export type Document = {
    label: string;
    fileName?: string;
    type?: 'pdf' | 'jpg' | 'doc' | 'xls';
    status?: 'pending' | 'error' | 'success';
    date?: Date;
    feedback?: string;
};

export type DocumentItemProps = {
    uploadable: boolean;
    uploading: boolean;
    hover?: boolean;
    highlight?: boolean;
    fileInputRef?: LegacyRef<HTMLLabelElement>;
    actions?: Array<DocumentAction>;
    color?: 'primary';
    sx?: SxProps;
    labelSx?: SxProps;
} & Document;

const getColorByStatus = (status: 'pending' | 'error' | 'success') => {
    if (status === 'error') {
        return 'error';
    }

    if (status === 'success') {
        return 'success';
    }

    return 'secondary';
};

const DocumentItem: React.FunctionComponent<DocumentItemProps> = function (props) {
    const {
        label,
        fileName,
        type,
        status = 'pending',
        date,
        feedback,
        uploadable = false,
        uploading = false,
        hover = false,
        highlight = false,
        fileInputRef,
        actions = [],
        color: colorProp,
        sx,
        labelSx,
    } = props;
    const color = colorProp || getColorByStatus(status);
    const { palette, breakpoints } = useTheme();
    const downMd = useMediaQuery(breakpoints.down('md'));
    const baseColor = palette[color].main;

    const wrapperSx = {
        display: 'flex',
        gap: '0 8px',
        alignItems: 'center',
        background: highlight ? palette.grey[100] : 'none',
        border: `solid 1px ${palette.grey[300]}`,
        borderRadius: '12px',
        padding: '8px',
        transition: '0.1s ease',
        boxShadow: hover ? `0px 2px 2.5px 0px ${palette.grey[200]}` : 'none',
        cursor: uploadable ? 'pointer' : 'auto',
        ...sx,
    };

    const avatarWrapperSx = { alignSelf: downMd ? 'flex-start' : 'auto', padding: '10px 8px' };

    const infoWrapperSx = {
        display: 'flex',
        flexDirection: 'column',
    };

    const nameSx = {
        color: status === 'success' ? palette.primary.dark : 'text.primary',
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: '500',
        ...labelSx,
    };

    const dateSx = {
        color: 'text.secondary',
        fontSize: '13px',
        lineHeight: '16px',
        fontWeight: '500',
    };

    const fileNameSx = {
        color: 'text.gris70',
    };

    const inlinedWrapperSx = {
        display: 'flex',
        flexDirection: downMd ? 'column' : 'row',
        alignItems: downMd ? 'flex-start' : 'center',
        gap: downMd ? '4px 0' : '0 4px',
    };

    const actionWrapperSx = {
        display: 'flex',
        padding: '0 6.5px',
        gap: '0 20px',
        alignItems: 'center',
        marginLeft: 'auto',
    };

    return (
        <Box
            sx={wrapperSx}
            data-testid="document"
        >
            <Box sx={avatarWrapperSx}>
                <DocumentIcon
                    color={color}
                    icon={type ? `file-${type}` : 'file'}
                />
            </Box>
            <Box sx={infoWrapperSx}>
                <Box sx={inlinedWrapperSx}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '0 4px' }}>
                        <Typography
                            component="span"
                            sx={nameSx}
                        >
                            {label}
                        </Typography>
                        {date && (
                            <Typography
                                component="span"
                                sx={dateSx}
                            >
                                {date.toLocaleDateString(undefined, {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                })}
                            </Typography>
                        )}
                    </Box>
                    {status !== 'success' && feedback && (
                        <InformationChip
                            color={color}
                            label={
                                <Typography
                                    variant="l2dbodys"
                                    color={baseColor}
                                >
                                    {feedback}
                                </Typography>
                            }
                        />
                    )}
                </Box>
                {fileName && (
                    <Box sx={inlinedWrapperSx}>
                        <Typography
                            component="span"
                            sx={fileNameSx}
                            variant="l2dbodyxs"
                        >
                            {fileName}
                        </Typography>
                    </Box>
                )}
            </Box>
            <Box sx={actionWrapperSx}>
                {uploading && <CircularProgress size="16px" />}
                {!downMd && !uploading && uploadable && fileInputRef && (
                    <UploadButton fileInputRef={fileInputRef} />
                )}
                {!uploading && actions.length > 0 && <DocumentActions actions={actions} />}
            </Box>
        </Box>
    );
};

export default DocumentItem;
