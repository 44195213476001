import React, { useEffect } from 'react';

/** layouts */
import {
    FullLayout,
    FullLayoutProps,
    SnackbarCloseButton,
} from '@europrocurement/flexy-components';

/** end layouts */

/** pages */
import { Box } from '@mui/material';
import { SnackbarAction, SnackbarKey, SnackbarProvider } from 'notistack';
import {
    B2DSymboleIcon,
    L2DFLogoIcon,
    L2DFLogoSmallIcon,
    plusIcon,
} from '@europrocurement/l2d-icons';
import { useLocation, useNavigate } from 'react-router';
import { parcoursFormalitePathResolver } from '@europrocurement/l2d-modules';
import usePrescriber from '@l2df/hook/usePrescriber';
import {
    setPrescripteurs,
    usePrescripteursCustomizer,
    useSociete,
    useTiersService,
} from '@europrocurement/l2d-domain';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { FullLayoutProvider } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import L2DFCustomHeader from './L2DFCustomHeader';

/** end pages */

/** menu */

/** end menu */

const L2DFlayout: React.FunctionComponent<Omit<FullLayoutProps, 'logo' | 'logoFolded'>> = function (
    props,
) {
    const ToCaptureRef = React.useRef<HTMLDivElement>(null); // ref

    const closeBtn: SnackbarAction = (key: SnackbarKey) => (
        <SnackbarCloseButton snackbarKey={key} />
    );

    const navigate = useNavigate();
    const location = useLocation();

    const { prescriberId, prescriberIds } = usePrescriber();
    const { prescribersApi } = useTiersService();
    const xIdSociete = useSociete();
    const dispatch = useDispatch();
    const prescribersCustomizer = usePrescripteursCustomizer();

    useEffect(() => {
        if (location.pathname !== '/errors/noPrescriber' && prescriberId === undefined) {
            navigate('/errors/noPrescriber');
        }
    }, [location.pathname, navigate, prescriberId]);

    /**
     * Use effect qui va chercher tous les prescripteurs liés a l'utilisateur
     */
    useEffect(() => {
        /**
         * on compare les ids prescripteur du token et ceux du customize
         */
        const numberTokenIds = prescriberIds?.map((item) => Number(item)).sort();
        const numberCustoIds = prescribersCustomizer?.map((item) => Number(item.id)).sort();
        if (!_.isEqual(numberTokenIds, numberCustoIds)) {
            /**
             * si les tableaux sont diférents, on va chercher les prescripteurs
             */
            const proms = numberTokenIds?.map((id) =>
                prescribersApi.getTiersTiersItem({
                    id: `${id}`,
                    xIdSociete,
                }),
            );
            if (proms) {
                Promise.all(proms).then((res) => {
                    dispatch(
                        setPrescripteurs(
                            res
                                .filter((item) => item.status === 200)
                                .map((prescripteurRes) => ({
                                    id: prescripteurRes.data.id,
                                    raisonSociale: prescripteurRes.data.raisonSociale,
                                    siren: prescripteurRes.data.siren,
                                    siret: prescripteurRes.data.siret,
                                })),
                        ),
                    );
                });
            }
        }
    }, [dispatch, prescriberId, prescriberIds, prescribersApi, prescribersCustomizer, xIdSociete]);

    const layoutProps: FullLayoutProps = {
        ...props,
        defaultContextActions: [],
        contextActionMainIcon: (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <B2DSymboleIcon style={{ maxHeight: '24px' }} />
            </Box>
        ),
        logo: <L2DFLogoIcon style={{ width: '100%', height: '100%' }} />,
        logoFolded: <L2DFLogoSmallIcon style={{ width: '100%', height: '100%' }} />,
        sidebarActions: [
            {
                icon: { ...plusIcon.props },
                label: 'Nouveau Dossier',
                onClick: () => {
                    navigate(parcoursFormalitePathResolver('stepFormalite'));
                },
            },
        ],
        pageTitle: 'Legal2digital Formalités',
    };

    return (
        <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
            }}
            action={closeBtn}
            variant="success"
        >
            <div ref={ToCaptureRef}>
                <FullLayoutProvider>
                    <FullLayout
                        {...layoutProps}
                        headerProps={{
                            showPrescripteur: true,
                            showSociete: false,
                            customHeader: <L2DFCustomHeader />,
                        }}
                    />
                </FullLayoutProvider>
            </div>
        </SnackbarProvider>
    );
};

export default L2DFlayout;
