import React, { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import DocumentItem from '../DocumentItem/DocumentItem';

export type DisplayedDownloadableDocument = {
    fileName: string;
    fichierId: number;
};

export type DownloadableDocumentItemProps = {
    fileName: string;
    onDownload: () => void;
};

const DownloadableDocumentItem: React.FunctionComponent<DownloadableDocumentItemProps> = function (
    props,
) {
    const { fileName, onDownload: handleDownload } = props;
    const [isMouseHover, setIsMouseHover] = useState<number>(0);
    const { palette } = useTheme();

    const handleMouseEnter = () => setIsMouseHover((val) => val + 1);
    const handleMouseLeave = () => setIsMouseHover((val) => val - 1);

    return (
        <Box
            data-testid="downloadable-documents-item"
            onClick={handleDownload}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <DocumentItem
                label={fileName}
                hover={isMouseHover > 0}
                uploadable={false}
                uploading={false}
                color="primary"
                sx={{ cursor: 'pointer' }}
                labelSx={{ color: palette.primary.dark }}
            />
        </Box>
    );
};

export default DownloadableDocumentItem;
