import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import img from './no-document.png';

export type NoResultProps = {
    title: string;
    description?: string;
};

const NoResult: React.FunctionComponent<NoResultProps> = function (props) {
    const { title, description } = props;
    const { palette, breakpoints } = useTheme();
    const downMd = useMediaQuery(breakpoints.down('md'));

    return (
        <Box
            data-testid="no-result"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: downMd ? '50px 0' : '175px 0 265px 0',
            }}
        >
            <img
                src={img}
                alt={title}
            />
            <Typography
                variant="l2dh6"
                sx={{ margin: '32px 0 8px 0', color: palette.text.primary }}
            >
                {title}
            </Typography>
            {description && (
                <Typography
                    variant="l2dbodym"
                    sx={{ textAlign: 'center', color: palette.grey[700] }}
                >
                    {description}
                </Typography>
            )}
        </Box>
    );
};

export default NoResult;
