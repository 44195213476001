import { ConfirmationModal, useModal } from '@europrocurement/flexy-components';
import React, { useEffect } from 'react';
import { useTheme } from '@mui/material';
import JustificationModal from '../JustificationModal/JustificationModal';

export type ChangeDossierStatusModalProps = {
    title: string;
    description: string;
    showModal: boolean;
    withJustification: boolean;
    onConfirm: (message?: string) => void;
    onClose: () => void;
};

const ChangeDossierStatusModal: React.FunctionComponent<ChangeDossierStatusModalProps> = function (
    props,
) {
    const { title, description, showModal, withJustification, onConfirm, onClose } = props;
    const { modalStatus, modalActions } = useModal();
    const { palette } = useTheme();
    let modal;

    if (withJustification) {
        modal = (
            <JustificationModal
                title={title}
                description={description}
                onConfirm={onConfirm}
                onCancel={onClose}
            />
        );
    } else {
        modal = (
            <ConfirmationModal
                messages={{
                    question: title,
                    detail: {
                        message: description,
                        severity: 'warning',
                    },
                }}
                actionOnValidation={() => {
                    onConfirm();
                }}
                actionOnCancellation={onClose}
                color={palette.warning}
            />
        );
    }

    useEffect(() => {
        if (showModal) {
            modalActions.call(modal);
        } else {
            modalActions.close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    useEffect(() => {
        if (!modalStatus.isOpen) {
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalStatus.isOpen]);

    return null;
};

export default ChangeDossierStatusModal;
