import React, { useContext, useState } from 'react';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { DossierOpenSearch } from '@europrocurement/l2d-domain';
import { DossierActionContext, useTransition } from '@europrocurement/l2d-modules';

export type ActionMenuProps = {
    dossier: DossierOpenSearch;
};

const ActionMenu: React.FunctionComponent<ActionMenuProps> = function (props) {
    const { dossier } = props;
    const { triggerAction } = useContext(DossierActionContext);
    const { canApplyTransition } = useTransition();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDossierGroupModal = () => {
        triggerAction('manageGroups', [dossier]);
        setAnchorEl(null);
    };

    const handleSuspendDossier = () => {
        triggerAction('suspend', [dossier]);
        setAnchorEl(null);
    };

    const handleCancelDossier = () => {
        triggerAction('cancel', [dossier]);
        setAnchorEl(null);
    };

    const canSuspend = canApplyTransition(dossier, 'suspend');
    const canCancel = canApplyTransition(dossier, 'cancel');

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
        >
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <FaOptionIcon
                    icon={['fasl', 'ellipsis-vertical']}
                    sx={{ fontSize: '1rem' }}
                />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleOpenDossierGroupModal}>Déplacer dans un groupe</MenuItem>
                {canSuspend && <MenuItem onClick={handleSuspendDossier}>Suspendre</MenuItem>}
                {canCancel && (
                    <MenuItem
                        onClick={handleCancelDossier}
                        sx={{ color: 'error.main' }}
                    >
                        Annuler
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

export default ActionMenu;
