import {
    BillingProvider,
    DocumentPreview,
    DocumentPreviewProvider,
    DocumentProvider,
    DossierPrescriberProvider,
    DossierRoutingProvider,
    PrescriberDossierBody,
    SelectedDossierProvider,
} from '@europrocurement/l2d-modules';
import { Container } from '@mui/material';
import React from 'react';
import { dossiersBasePath } from '../../constants';
import { ordersBasePath } from '../../../Orders';
import { DetailDossierHeader } from '../../components';

const DetailDossier: React.FunctionComponent = function () {
    return (
        <DossierRoutingProvider
            orderBasePath={ordersBasePath}
            dossierBasePath={dossiersBasePath}
        >
            <DocumentPreviewProvider>
                <Container maxWidth="lg">
                    <SelectedDossierProvider>
                        <DossierPrescriberProvider>
                            <DocumentProvider>
                                <BillingProvider>
                                    <DetailDossierHeader />
                                    <PrescriberDossierBody />
                                </BillingProvider>
                            </DocumentProvider>
                        </DossierPrescriberProvider>
                    </SelectedDossierProvider>
                </Container>
                <DocumentPreview />
            </DocumentPreviewProvider>
        </DossierRoutingProvider>
    );
};

export default DetailDossier;
