import React, { useContext, useEffect, useState } from 'react';
import { CardHeader, CardSection } from '@europrocurement/flexy-components';
import { enqueueSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import { DocumentContext } from '../../../providers';
import { DossierDocument, DossierFichier, useDossierDocuments } from '../../../hooks';
import { DisplayedDownloadableDocument } from '../DownloadableDocumentItem/DownloadableDocumentItem';
import DownloadableDocumentsList from '../DownloadableDocumentsList/DownloadableDocumentsList';

const DownloadableDocumentsTab: React.FunctionComponent = function () {
    const [listItems, setListItems] = useState<Array<DisplayedDownloadableDocument>>([]);
    const { documents, fichiers } = useContext(DocumentContext);
    const {
        getFichierFromDocument,
        documentHasFichier,
        downloadFichier,
        isFormalisteDocumentType,
        isNoType,
    } = useDossierDocuments();

    useEffect(() => {
        const displayedDocuments = documents
            .filter(
                (document: DossierDocument) =>
                    !isNoType(document?.document?.id as number) &&
                    isFormalisteDocumentType(document?.document?.type as number) &&
                    documentHasFichier(document, fichiers),
            )
            .map((document): DisplayedDownloadableDocument => {
                const fichier = getFichierFromDocument(document, fichiers);

                return {
                    fileName: document.libelle as string,
                    fichierId: fichier?.id as number,
                };
            });

        setListItems(displayedDocuments);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documents, fichiers]);

    const handleDownload = async (fichierId: number) => {
        const fichier = fichiers.find((f: DossierFichier) => Number(f.id) === fichierId);

        if (!fichier) {
            enqueueSnackbar(<Typography>Une erreur est survenue</Typography>, { variant: 'error' });

            return;
        }

        try {
            await downloadFichier(fichier);
        } catch (err) {
            console.error(err);
            enqueueSnackbar(<Typography>Une erreur est survenue</Typography>, { variant: 'error' });
        }
    };

    return (
        <CardSection
            header={
                <CardHeader
                    title="Documents remis"
                    titleSx={{
                        fontSize: '20px',
                        lineHeight: '28px',
                    }}
                    border={false}
                />
            }
            sxContent={{
                padding: '8px 32px 32px',
                display: 'flex',
                flexDirection: 'column',
                gap: '24px 0',
            }}
        >
            <DownloadableDocumentsList
                documents={listItems}
                onDownload={handleDownload}
            />
        </CardSection>
    );
};

export default DownloadableDocumentsTab;
