import React, { useState } from 'react';
import {
    Box,
    CircularProgress,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { InformationChip } from '@europrocurement/flexy-components';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { enqueueSnackbar } from 'notistack';
import DocumentIcon from '../DocumentIcon/DocumentIcon';

export type InvoiceStatus = 'paid' | 'pending';

export type InvoiceItemProps = {
    label: string;
    status: InvoiceStatus;
    feedback?: string;
    id?: number;
    onDownload?: (fileName: string, id: number) => Promise<void>;
    onShow?: (fileName: string, id: number) => Promise<void>;
};

const getColorByStatus = (status: InvoiceStatus) => (status === 'paid' ? 'success' : 'error');

const InvoiceItem: React.FunctionComponent<InvoiceItemProps> = function (props) {
    const { id, label, status = 'pending', feedback, onDownload, onShow } = props;
    const color = getColorByStatus(status);
    const { palette, breakpoints } = useTheme();
    const downMd = useMediaQuery(breakpoints.down('md'));
    const [hover, setHover] = useState<boolean>(false);
    const [downloadProcessing, setDownloadProcessing] = useState<boolean>(false);
    const [showProcessing, setShowProcessing] = useState<boolean>(false);
    const baseColor = palette[color].main;

    const handleMouseEnter = () => setHover(true);
    const handleMouseLeave = () => setHover(false);

    const handleDownload = () => {
        if (!id || !onDownload) {
            return;
        }

        setDownloadProcessing(true);
        onDownload(`${label}.pdf`, id)
            .then(() => {})
            .catch(() =>
                enqueueSnackbar('Une erreur est survenue lors du téléchargement du document', {
                    variant: 'error',
                }),
            )
            .finally(() => setDownloadProcessing(false));
    };

    const handleShow = () => {
        if (!id || !onShow) {
            return;
        }

        setShowProcessing(true);
        onShow(`${label}.pdf`, id)
            .then(() => {})
            .catch(() =>
                enqueueSnackbar('Une erreur est survenue lors du téléchargement du document', {
                    variant: 'error',
                }),
            )
            .finally(() => setShowProcessing(false));
    };

    const wrapperSx = {
        display: 'flex',
        gap: '0 8px',
        alignItems: 'center',
        border: `solid 1px ${palette.grey[300]}`,
        borderRadius: '12px',
        padding: '8px',
        transition: '0.1s ease',
        boxShadow: hover ? `0px 2px 2.5px 0px ${palette.grey[200]}` : 'none',
        cursor: 'pointer',
    };

    const avatarWrapperSx = {
        alignSelf: downMd ? 'flex-start' : 'auto',
        padding: '10px 8px',
    };

    const infoWrapperSx = {
        display: 'flex',
        flexDirection: 'column',
    };

    const nameSx = {
        color: palette.primary.dark,
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: '500',
    };

    const inlinedWrapperSx = {
        display: 'flex',
        flexDirection: downMd ? 'column' : 'row',
        alignItems: downMd ? 'flex-start' : 'center',
        gap: downMd ? '4px 0' : '0 4px',
    };

    const actionWrapperSx = {
        display: 'flex',
        padding: '0 6.5px',
        gap: '0 20px',
        alignItems: 'center',
        marginLeft: 'auto',
    };

    const actionButtonIconSx = {
        display: 'flex',
        padding: '0',
        justifyContent: 'center',
        alignItems: 'center',
        width: '25px',
        height: '25px',
    };

    const actionIconSx = {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '16px',
    };

    return (
        <Box
            sx={wrapperSx}
            data-testid="invoice-item"
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
        >
            <Box sx={avatarWrapperSx}>
                <DocumentIcon
                    color={color}
                    icon="file-pdf"
                />
            </Box>
            <Box sx={infoWrapperSx}>
                <Box sx={inlinedWrapperSx}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '0 4px' }}>
                        <Typography
                            component="span"
                            sx={nameSx}
                        >
                            {label}
                        </Typography>
                    </Box>
                    {feedback && (
                        <InformationChip
                            color={color}
                            label={
                                <Typography
                                    variant="l2dbodys"
                                    color={baseColor}
                                >
                                    {feedback}
                                </Typography>
                            }
                        />
                    )}
                </Box>
            </Box>
            <Box sx={actionWrapperSx}>
                {showProcessing && <CircularProgress size="16px" />}
                {!showProcessing && id && onShow && (
                    <Tooltip title="Voir la facture">
                        <IconButton
                            sx={actionButtonIconSx}
                            onClick={() => handleShow()}
                            data-testid="invoice-item-show-btn"
                        >
                            <FaOptionIcon
                                icon={['fasl', 'eye']}
                                color="text.secondary"
                                sx={actionIconSx}
                            />
                        </IconButton>
                    </Tooltip>
                )}
                {downloadProcessing && <CircularProgress size="16px" />}
                {!downloadProcessing && id && onDownload && (
                    <Tooltip title="Télécharger la facture">
                        <IconButton
                            sx={actionButtonIconSx}
                            onClick={() => handleDownload()}
                            data-testid="invoice-item-download-btn"
                        >
                            <FaOptionIcon
                                icon={['fasl', 'arrow-down-to-line']}
                                color="text.secondary"
                                sx={actionIconSx}
                            />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Box>
    );
};

export default InvoiceItem;
