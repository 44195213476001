import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import DocumentIcon from '../DocumentIcon/DocumentIcon';

export type DocumentPreviewHeaderProps = {
    fileName: string;
    onClose: () => void;
};

const DocumentPreviewHeader: React.FunctionComponent<DocumentPreviewHeaderProps> = function (
    props,
) {
    const color = 'primary';
    const { palette } = useTheme();

    const { fileName, onClose: handleClose } = props;

    const headerSx = {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        padding: '30px',
    };

    const avatarWrapperSx = {};

    const nameWrapperSx = {};
    const nameSx = { color: palette.primary.dark };

    const closeWrapperSx = {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: 0,
        marginLeft: 'auto',
        width: '30px',
        height: '30px',
        minWidth: 'auto',
        minHeight: 'auto',
    };

    const closeSx = {
        color: palette.grey[500],
        fontSize: '24px',
        lineHeight: '24px',
    };

    return (
        <Box sx={headerSx}>
            <Box sx={avatarWrapperSx}>
                <DocumentIcon
                    color={color}
                    icon="file"
                />
            </Box>
            <Box sx={nameWrapperSx}>
                <Typography
                    component="span"
                    sx={nameSx}
                    variant="l2dh7"
                >
                    {fileName}
                </Typography>
            </Box>
            <Button
                onClick={handleClose}
                sx={closeWrapperSx}
                data-testid="close-button"
            >
                <FaOptionIcon
                    sx={closeSx}
                    icon={['fasl', 'close']}
                />
            </Button>
        </Box>
    );
};

export default DocumentPreviewHeader;
