import { Dossier, DossierOpenSearch, useFormaliteService } from '@europrocurement/l2d-domain';
import { ApiDossiersIdActionPutActionEnum } from '@europrocurement/l2d-domain/openApi/ApiFormalite';

type Transition = ApiDossiersIdActionPutActionEnum;

const STEP_DRAFT = 1;
const STEP_TRANSMITTED = 2;

const useTransition = () => {
    const { dossierApi } = useFormaliteService();

    const softActionSteps = [STEP_DRAFT, STEP_TRANSMITTED];

    const canApplyTransition = (dossier: DossierOpenSearch, transition: Transition): boolean =>
        (dossier?.transitions || []).includes(transition);

    const applyTransition = (
        dossier: DossierOpenSearch,
        transition: Transition,
    ): Promise<Dossier> =>
        new Promise((resolve, reject) => {
            dossierApi
                .apiDossiersIdActionPut({ id: dossier?.id as number, action: transition })
                .then((res) => resolve(res.data as Dossier))
                .catch((err) => reject(err));
        });

    return { canApplyTransition, applyTransition, softActionSteps };
};

export default useTransition;
