const useCancelDossier = () => {
    const getModalTitle = (identifiers: string[]) =>
        identifiers.length > 1
            ? `Annulation des dossiers ${identifiers.join(', ')}`
            : `Annulation du dossier ${identifiers.join(', ')}`;

    const getModalDescription = (softAction: boolean, plural: boolean) => {
        if (softAction) {
            return 'Êtes-vous sûr de vouloir effectuer cette action ?';
        }

        if (plural) {
            return "Au moins l'un de vos dossiers est en cours de traitement par nos formalistes, des coûts peuvent s’appliquer en fonction des actions déjà entreprises par nos équipes. Veuillez renseigner ci-dessous la raison de votre demande d’annulation.";
        }

        return 'Votre dossier est en cours de traitement par nos formalistes, des coûts peuvent s’appliquer en fonction des actions déjà entreprises par nos équipes. Veuillez renseigner ci-dessous la raison de votre demande d’annulation.';
    };

    const getConfirmationMessage = (identifiers: string[]) => {
        if (identifiers.length > 1) {
            return `Vos demandes d’annulation des dossiers ${identifiers} ont bien été transmises à nos équipes. Nous revenons vers vous au plus vite.`;
        }

        if (identifiers.length === 1) {
            return `Votre demande d’annulation du dossier ${identifiers} a bien été transmise à nos équipes. Nous revenons vers vous au plus vite.`;
        }

        return 'Votre demande d’annulation a bien été transmise à nos équipes. Nous revenons vers vous au plus vite.';
    };

    return { getModalTitle, getModalDescription, getConfirmationMessage };
};

export default useCancelDossier;
