import { DossierOpenSearch } from '@europrocurement/l2d-domain';
import { DossierActionContext, useTransition } from '@europrocurement/l2d-modules';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Typography } from '@mui/material';
import React, { useContext } from 'react';

const useDossierDetail = () => {
    const { canApplyTransition } = useTransition();
    const { triggerAction } = useContext(DossierActionContext);

    const getAvailableActions = (dossier: DossierOpenSearch) => {
        const actions = [];

        if (canApplyTransition(dossier, 'suspend')) {
            actions.push({
                label: 'Suspendre',
                icon: ['fasl', 'hourglass-clock'] as IconProp,
                isAdditional: false,
                onClick: () => triggerAction('suspend', [dossier]),
            });
        }

        if (canApplyTransition(dossier, 'cancel')) {
            actions.push({
                label: <Typography sx={{ color: 'error.main' }}>Annuler</Typography>,
                icon: ['fasl', 'ban'] as IconProp,
                isAdditional: false,
                onClick: () => triggerAction('cancel', [dossier]),
            });
        }

        return actions;
    };

    return { getAvailableActions };
};

export default useDossierDetail;
