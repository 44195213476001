import React from 'react';
import { Box, Typography } from '@mui/material';

export type InvoicePaymentProps = {
    amount: number;
};

const InvoicePayment: React.FunctionComponent<InvoicePaymentProps> = function (props) {
    const { amount } = props;
    const wrapperSx = {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
    };

    const labelSx = {
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '21px',
    };

    const amountLabelSx = {
        fontSize: '22px',
        fontWeight: '600',
        lineHeight: '26.4px',
    };

    return (
        <Box
            sx={wrapperSx}
            data-testid="invoice-payment"
        >
            <Typography sx={labelSx}>Montant (TTC) à payer</Typography>
            <Typography sx={amountLabelSx}>
                {(Math.floor(amount * 100) / 100).toFixed(2)}&euro;
            </Typography>
        </Box>
    );
};

export default InvoicePayment;
