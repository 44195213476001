import React, { useContext, useEffect, useState } from 'react';
import {
    DossierOpenSearch,
    MESSAGE_FROM_PRESCRIBER,
    useFormaliteService,
} from '@europrocurement/l2d-domain';
import { enqueueSnackbar } from 'notistack';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { DossierActionContext } from '../../../providers';
import { useTransition } from '../../../hooks';
import ChangeDossierStatusModal from '../ChangeDossierStatusModal/ChangeDossierStatusModal';
import useSuspendDossier from './useSuspendDossier';

const SuspendDossierModal: React.FunctionComponent = function () {
    const { currentAction, cancelCurrentAction, confirmCurrentAction, dossiers } =
        useContext(DossierActionContext);
    const [showModal, setShowModal] = useState<boolean>(false);
    const { applyTransition, softActionSteps } = useTransition();
    const { messageApi } = useFormaliteService();
    const kc = UseKeycloakService();
    const { getModalTitle, getModalDescription, getConfirmationMessage } = useSuspendDossier();

    useEffect(() => {
        const shouldShowModal = currentAction === 'suspend';
        if (shouldShowModal !== showModal) {
            setShowModal(shouldShowModal);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAction]);

    const identifiers = dossiers
        .map((dossier: DossierOpenSearch) => dossier?.numero)
        .filter((numero) => numero !== undefined && numero !== null);

    const isSoftAction =
        dossiers.find((dossier) => softActionSteps.includes(dossier?.statutId as number)) !==
        undefined;

    const handleConfirmation = async (message?: string) => {
        let allPromises: Array<Promise<unknown>> = dossiers.map((dossier) =>
            applyTransition(dossier, 'suspend'),
        );

        if (message) {
            allPromises = [
                ...allPromises,
                ...dossiers.map((dossier) =>
                    messageApi.apiMessagesPost({
                        messageJsonldMessageCreate: {
                            expediteur: MESSAGE_FROM_PRESCRIBER,
                            expediteurNom: kc.getName(),
                            readByPrescri: true,
                            readByFrmlist: false,
                            message,
                            dossier: `/formalite/dossiers/${dossier?.id}`,
                        },
                    }),
                ),
            ];
        }

        try {
            await Promise.all(allPromises);

            enqueueSnackbar(getConfirmationMessage(identifiers));
        } catch (err) {
            console.error(err);
            cancelCurrentAction();
            enqueueSnackbar('Une erreur est survenue lors de la mise à jour de votre dossier', {
                variant: 'error',
            });
        }

        confirmCurrentAction();
    };

    const handleModalClose = () => {
        cancelCurrentAction();
    };

    return (
        <ChangeDossierStatusModal
            title={getModalTitle(identifiers)}
            description={getModalDescription(isSoftAction, dossiers.length > 1)}
            showModal={showModal}
            withJustification={!isSoftAction}
            onConfirm={handleConfirmation}
            onClose={handleModalClose}
        />
    );
};

export default SuspendDossierModal;
