import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Devis, FactureFormalite } from '@europrocurement/l2d-domain';
import { useParams } from 'react-router-dom';
import { useBilling } from '../hooks';
import BillingContext from './BillingContext';

const BillingProvider: React.FunctionComponent<PropsWithChildren> = function ({ children }) {
    const { idDossier } = useParams();
    const { getEstimates, getInvoices } = useBilling();
    const [estimates, setEstimates] = useState<Array<Devis>>([]);
    const [invoices, setInvoices] = useState<Array<FactureFormalite>>([]);
    const [estimatesLoaded, setEstimatesLoaded] = useState<boolean>(false);
    const [invoicesLoaded, setInvoicesLoaded] = useState<boolean>(false);

    useEffect(() => {
        setEstimatesLoaded(false);
        setInvoicesLoaded(false);
        getEstimates(idDossier as unknown as number)
            .then(setEstimates)
            .catch(() => {
                setEstimates([]);
                console.error('An error occurred');
            })
            .finally(() => setEstimatesLoaded(true));
        getInvoices(idDossier as unknown as number)
            .then(setInvoices)
            .catch(() => {
                setInvoices([]);
                console.error('An error occurred');
            })
            .finally(() => setInvoicesLoaded(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idDossier]);

    const contextValue = useMemo(
        () => ({
            estimates,
            invoices,
            estimatesLoaded,
            invoicesLoaded,
        }),
        [estimates, invoices, estimatesLoaded, invoicesLoaded],
    );

    return <BillingContext.Provider value={contextValue}>{children}</BillingContext.Provider>;
};

export default BillingProvider;
