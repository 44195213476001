import React, { LegacyRef } from 'react';
import { Button, Typography, useTheme } from '@mui/material';

export type UploadButtonProps = {
    fileInputRef: LegacyRef<HTMLLabelElement>;
};

const UploadButton: React.FunctionComponent<UploadButtonProps> = function (props) {
    const { fileInputRef } = props;
    const { palette } = useTheme();

    const uploadActionSx = {
        display: 'flex',
        alignItems: 'center',
        gap: '0 5px',
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: '500',
    };

    const uploadActionBtnSx = {
        padding: '0',
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: '500',
        color: `${palette.primary.dark}`,
    };

    return (
        <Typography
            component="p"
            sx={uploadActionSx}
        >
            <Button
                data-testid="upload-btn"
                sx={uploadActionBtnSx}
                variant="text"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    fileInputRef?.current?.click();
                }}
            >
                Sélectionnez
            </Button>
            ou glissez ici
        </Typography>
    );
};

export default UploadButton;
