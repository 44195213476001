import React, { useContext, useEffect, useState } from 'react';
import {
    DetailDossierTitle,
    DetailDossierToolbar,
    DossierAction,
    DossierActionContext,
    SelectedDossierContext,
} from '@europrocurement/l2d-modules';
import { useFormaliteService } from '@europrocurement/l2d-domain';
import { useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { useDossierDetail } from '../../hooks';

const DetailDossierHeader: React.FunctionComponent = function () {
    const { dossier } = useContext(SelectedDossierContext);
    const { dossiersUpdated, setDossiersUpdated } = useContext(DossierActionContext);
    const { selectOpenSearchDossier } = useFormaliteService();
    const { getAvailableActions } = useDossierDetail();
    const dispatch = useDispatch();
    const [dossierActions, setDossierActions] = useState<Array<DossierAction>>([]);

    useEffect(() => {
        setDossierActions(getAvailableActions(dossier));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dossier]);

    useEffect(() => {
        if (dossiersUpdated.length) {
            setDossiersUpdated([]);
            dispatch(
                selectOpenSearchDossier({
                    id: dossier.id as unknown as number,
                }) as unknown as AnyAction,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dossiersUpdated]);

    return (
        <>
            <DetailDossierToolbar
                id={dossier.id as number}
                reference={dossier.numero as string}
                orderId={dossier.collectionId as number}
                orderReference={dossier.collectionNumero as string}
                createdAt={dossier.dateCreation as string}
                actions={dossierActions}
            />
            <DetailDossierTitle
                color="primary"
                title={dossier.societeDenomination as string}
                statusName={dossier.statutLibelle as string}
                clientReference={dossier.societeRef as string}
                prescriberName={dossier.prescripteurLibelle as string}
                clientName={dossier.clientOpereLibelle as string}
            />
        </>
    );
};

export default DetailDossierHeader;
