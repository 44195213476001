import React, { useContext, useEffect, useState } from 'react';
import { CardHeader, CardSection } from '@europrocurement/flexy-components';
import { Box, CircularProgress, Divider } from '@mui/material';
import { useDownload } from '@europrocurement/l2d-modules/hooks';
import {
    BillingContext,
    DocumentPreviewContext,
    DossierPrescriberContext,
    SelectedDossierContext,
} from '../../../providers';
import { UpdateDossierPayload, useBilling, useDossierPrescripteur } from '../../../hooks';
import { InvoiceItemProps, InvoiceStatus } from '../InvoiceItem/InvoiceItem';
import BillingInfo, {
    BILLING_TYPE_VD,
    BILLING_TYPE_VI_L2D,
    BILLING_TYPE_VI_PRESCRIBER,
} from '../BillingInfo/BillingInfo';
import InvoiceList from '../InvoiceList/InvoiceList';
import InvoicePayment from '../InvoicePayment/InvoicePayment';
import NoResult from '../NoResult/NoResult';

const BillingTab: React.FunctionComponent = function () {
    const { dossier, updateDossier } = useContext(SelectedDossierContext);
    const { setDocumentPreview } = useContext(DocumentPreviewContext);
    const { prescriber, setPrescriberId, sousClients, prescriberLoaded } =
        useContext(DossierPrescriberContext);
    const { estimates, invoices, estimatesLoaded, invoicesLoaded } = useContext(BillingContext);
    const { getBillingAddress } = useDossierPrescripteur();
    const { download } = useDownload();
    const {
        getEstimateLabel,
        getInvoiceLabel,
        canShowInvoice,
        isInvoicePaid,
        getInvoiceRemainingAmount,
        downloadInvoice,
        canUpdateBillingInfos,
    } = useBilling();
    const clientId = dossier?.clientId as number;
    const defaultBillingType = dossier?.typeFacturation as number;
    const clientOpereId = dossier?.clientOpereId as number;
    const prescriberId = dossier?.prescripteurId as number;
    const [recipientId, setRecipientId] = useState<number>(clientId);
    const [billingType, setBillingType] = useState<number>(defaultBillingType);
    const [invoiceItems, setInvoiceItems] = useState<Array<InvoiceItemProps>>([]);
    const [totalRemainingAmount, setItotalRemainingAmount] = useState<number>(0);
    const clientAddresses = sousClients.map((client) => ({
        clientId: client.id as number,
        label: client.raisonSociale,
        siren: client.siren as string,
        address: getBillingAddress(client),
    }));
    const hasClientAddress =
        prescriberId === clientId ||
        sousClients.find((sousClient) => sousClient.id === clientId) !== undefined;

    if (!hasClientAddress) {
        clientAddresses.push({
            clientId,
            label: dossier?.clientLibelle as string,
            siren: dossier?.clientSiren as string,
            address: dossier?.clientAdresseComplete as string,
        });
    }

    const handleRecipientChange = (newRecipientId?: number) => {
        setRecipientId(newRecipientId || clientOpereId);
    };

    const handleBillingTypeChange = (newBillingType: number) => {
        setBillingType(newBillingType);
    };

    const handleDownload = (fileName: string, id: number) =>
        new Promise((resolve, reject) => {
            downloadInvoice(id)
                .then((fileContent: Blob) => {
                    download(fileName, fileContent);

                    resolve(undefined);
                })
                .catch(reject);
        });

    const handleShow = (fileName: string, id: number) =>
        new Promise((resolve, reject) => {
            downloadInvoice(id)
                .then((fileContent: Blob) => {
                    setDocumentPreview({
                        fileName,
                        content: fileContent,
                    });

                    resolve(undefined);
                })
                .catch(reject);
        });

    useEffect(() => {
        setPrescriberId(prescriberId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prescriberId]);

    useEffect(() => {
        const payload: UpdateDossierPayload = {
            clientId: undefined,
            typeFacturation: undefined,
        };

        if (clientId !== recipientId) {
            payload.clientId = recipientId;
        }

        if (prescriberId === recipientId) {
            payload.typeFacturation = BILLING_TYPE_VI_PRESCRIBER;
        } else {
            const sousClient = sousClients.find((t) => t.id === recipientId);

            if (sousClient) {
                payload.typeFacturation = sousClient.venteIndirecte
                    ? BILLING_TYPE_VI_L2D
                    : BILLING_TYPE_VD;
            }
        }

        const newBillingType = payload.typeFacturation || defaultBillingType;
        const newClientId = payload.clientId || clientId;
        const hasChanges = newBillingType !== billingType || newClientId !== clientId;

        if (!hasChanges) {
            return;
        }

        if (newBillingType !== billingType) {
            setBillingType(newBillingType);
        }

        updateDossier({
            clientId: payload.clientId || clientId,
            typeFacturation: newBillingType,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipientId]);

    useEffect(() => {
        if (defaultBillingType !== billingType) {
            updateDossier({ typeFacturation: billingType });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billingType]);

    useEffect(() => {
        setInvoiceItems([
            ...estimates.map((estimate) => ({
                label: getEstimateLabel(estimate),
                status: 'paid' as InvoiceStatus,
            })),
            ...invoices
                .filter((invoice) => canShowInvoice(invoice))
                .map((invoice) => ({
                    label: getInvoiceLabel(invoice),
                    status: (isInvoicePaid(invoice) ? 'paid' : 'pending') as InvoiceStatus,
                    feedback: isInvoicePaid(invoice) ? 'Réglé' : 'À régler',
                    id: invoice.id as number,
                    onDownload: handleDownload,
                    onShow: handleShow,
                })),
        ]);
        setItotalRemainingAmount(
            invoices.reduce((prev, current) => prev + getInvoiceRemainingAmount(current), 0),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimates, invoices]);

    const allDataLoaded = prescriberLoaded && estimatesLoaded && invoicesLoaded;
    let billingBody = (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
            <CircularProgress />
        </Box>
    );

    if (allDataLoaded) {
        billingBody = (
            <>
                {invoiceItems.length > 0 && (
                    <>
                        {prescriber && (
                            <>
                                <BillingInfo
                                    prescriberId={prescriberId}
                                    recipientId={recipientId}
                                    prescriberLabel={dossier.prescripteurLibelle as string}
                                    prescriberSiren={dossier.prescripteurSiren as string}
                                    prescriberAddress={getBillingAddress(prescriber)}
                                    onRecipientChange={handleRecipientChange}
                                    addresses={clientAddresses}
                                    billingType={billingType}
                                    onBillingTypeChange={handleBillingTypeChange}
                                    canEdit={canUpdateBillingInfos(invoices)}
                                />
                                <Divider />
                            </>
                        )}
                        <InvoiceList items={invoiceItems} />
                        {totalRemainingAmount > 0 && (
                            <InvoicePayment amount={totalRemainingAmount} />
                        )}
                    </>
                )}
                {invoiceItems.length <= 0 && (
                    <NoResult
                        title="Aucune facture disponible pour le moment"
                        description="La commande est en attente d'estimation"
                    />
                )}
            </>
        );
    }

    return (
        <CardSection
            header={
                <CardHeader
                    title="Facturation"
                    titleSx={{
                        fontSize: '20px',
                        lineHeight: '28px',
                    }}
                    border={false}
                />
            }
            sxContent={{
                padding: '8px 32px 32px',
                display: 'flex',
                flexDirection: 'column',
                gap: '32px 0',
            }}
        >
            {billingBody}
        </CardSection>
    );
};

export default BillingTab;
